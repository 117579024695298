/**
 * Used to indicate that a request is a part of a larger group of actions.
 * Formatted as a UUID v4 without hyphens.
 *
 * Optional, but should be included when possible
 */
export const TRACE_ID_HEADER = "x-trace-id";

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Origin
 */
export const ACCESS_CONTROL_ALLOW_ORIGIN_HEADER = "Access-Control-Allow-Origin";

export const COOKIE_HEADER = "Cookie";

/**
 * Indicates what type of data is sent.
 */
export const CONTENT_TYPE_HEADER = "Content-Type";

export const CONTENT_TYPE_VALUE_JSON = "application/json";

/**
 * Can be used to provide credentials for authentication
 * such as bearer tokens.
 */
export const AUTHORIZATION_HEADER = "Authorization";

/**
 * Indicates what org this request should be made against.
 * Rquired for auth to function.
 */
export const ORG_ID_HEADER = "x-org-id";

/**
 * Used to prevent CSRF attacks, must be included for any
 * non-GET request and set to the string "safe"
 */
export const CSRF_SAFE_HEADER = "x-csrf-safe";

export const CSRF_SAFE_HEADER_VALUE = "safe";

/**
 * TEST ONLY - used to circumvent ws auth if and only if testing
 */
export const USER_ID_HEADER = "x-user-id";

/**
 * What of type agent is making the request.
 *
 * This value _should_ be @see AgentType but is not guaranteed to be.
 */
export const AGENT_TYPE_HEADER = "x-agent-type";

/**
 * The version of the agent making the request.
 *
 * This version this agent is for is described by @see AGENT_TYPE_HEADER
 */
export const AGENT_VERSION_HEADER = "x-agent-version";

/**
 * Lets the server accept arbitrary custom queries if present.
 *
 * Only works on local, staging, and develop stacks.
 */
export const ALLOW_ARBITRARY_QUERIES_HEADER = "x-allow-aribtrary-queries";

export const SIDECAR_FENCING_TOKEN_HEADER = "x-sidecar-fencing-token";
