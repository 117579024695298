import { FilledDynamicValueTableColumnType } from "../DynamicValue";
import { assertNever } from "../errors";
import { ColumnOperation, Filter, FilterGroup } from "../filter";
import { FilterCellColumnId } from "../idTypeBrands";

import {
  BinaryColumnPredicate,
  ColumnPredicate,
  CompoundColumnPredicate,
  ListBinaryColumnPredicate,
  UnaryColumnPredicate,
} from "./columnPredicateTypes";
import { ColumnFilter } from "./filterTypes";
import { DisplayTableColumnOutputType } from "./outputTypes";

export function displayTableFilterToFilterCellFilter(
  filter: ColumnFilter,
  columnTypes?: Record<string, DisplayTableColumnOutputType>,
): Filter | FilterGroup {
  const { column, predicate } = filter;
  let operation: ColumnOperation;
  if (
    UnaryColumnPredicate.guard(predicate) ||
    BinaryColumnPredicate.guard(predicate) ||
    ListBinaryColumnPredicate.guard(predicate)
  ) {
    operation = predicate;
  } else if (CompoundColumnPredicate.guard(predicate)) {
    return {
      operation: predicate.op,
      filters: predicate.args.map((pred) =>
        displayTableFilterToFilterCellFilter({
          column,
          predicate: pred,
          columnType: columnTypes?.[column] ?? filter.columnType,
        }),
      ),
    };
  } else {
    assertNever(predicate, predicate);
  }

  return {
    column: FilterCellColumnId.check(column),
    operation,
    columnType:
      columnTypes?.[column] ??
      filter.columnType ??
      FilledDynamicValueTableColumnType.UNKNOWN,
  };
}

export function columnFilterFullyConfigured(filter: ColumnFilter): boolean {
  if (filter.column == null || filter.column.trim().length === 0) {
    return false;
  }

  function predicateConfigured(predicate: ColumnPredicate): boolean {
    if (UnaryColumnPredicate.guard(predicate)) {
      return true;
    } else if (BinaryColumnPredicate.guard(predicate)) {
      return predicate.arg != null && predicate.arg.trim().length > 0;
    } else if (ListBinaryColumnPredicate.guard(predicate)) {
      return predicate.arg != null && predicate.arg.length > 0;
    } else if (CompoundColumnPredicate.guard(predicate)) {
      return predicate.args.every(predicateConfigured);
    } else {
      assertNever(predicate, predicate);
    }
  }

  return predicateConfigured(filter.predicate);
}

export function filterKey({
  column,
  columnType,
  predicate,
}: ColumnFilter): string {
  return `${column}//${columnType ?? ""}//${filterPredicateKey(predicate)}`;
}

function filterPredicateKey(predicate: ColumnPredicate): string {
  if (UnaryColumnPredicate.guard(predicate)) {
    return `${predicate.op}`;
  } else if (
    BinaryColumnPredicate.guard(predicate) ||
    ListBinaryColumnPredicate.guard(predicate)
  ) {
    return `${predicate.op}//${predicate.arg}`;
  } else if (CompoundColumnPredicate.guard(predicate)) {
    return `${predicate.op}//${predicate.args
      .map(filterPredicateKey)
      .join("//")}`;
  }
  assertNever(predicate, predicate);
}
